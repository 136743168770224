<template>
  <a-layout class="client-layout-con my-layout">
    <a-layout-header class="client-layout-header flex flex-j-c-sb" :class="{'client-layout-padding-lg': isBreakpoint}" v-show="!($store.state.isHeaderHidden)">
      <div class="header-left-con" :class="{'header-left-con-lg': isBreakpoint}">
        <div v-show="!($store.state.isLogoHidden)" class="flex flex-a-i-center">
          <img :src="$logo || '/images/logo.png'" v-if="!isBreakpoint" height="28" alt="Logo">
          <img :src="$logo || '/images/simple-logo.png'" v-else height="28" alt="Logo">
          <a-divider type="vertical" style="height: 24px; background-color: rgba(0, 0, 0, 0.2);margin: 0 8px;" />
        </div>
        <!-- <a-menu v-model:selectedKeys="activeKey" mode="horizontal" @select="onTabSelect" style="display: inline-block;margin-left: 18px;">
          <a-menu-item :key="clientPath.WORKBENCH">
            <template #icon>
              <img :width="18" :src="activeKey[0] === clientPath.WORKBENCH ? '/images/header/tab/dashboard-one-active.png' : '/images/header/tab/dashboard-one.png'" alt="">
            </template>
            <span class="tab-text">{{$t('header.workbench')}}</span>
          </a-menu-item>
          <a-menu-item :key="clientPath.PROJECT_LIST">
            <template #icon>
              <img :width="18" :src="activeKey[0] === clientPath.PROJECT_LIST ? '/images/header/tab/protect-active.png' : '/images/header/tab/protect.png'" alt="">
            </template>
            <span class="tab-text">{{$t('header.projectList')}}</span>
          </a-menu-item>
          <a-menu-item :key="clientPath.PROJECT_COLLECTION">
            <template #icon>
              <img :width="18" :src="activeKey[0] === clientPath.PROJECT_COLLECTION ? '/images/header/tab/protect-active.png' : '/images/header/tab/protect.png'" alt="">
            </template>
            <span class="tab-text">{{$t('header.projectCollection')}}</span>
          </a-menu-item>
        </a-menu> -->
        <div class="project-list-title" id="projectListTitle">
          <a-dropdown :trigger="['click']">
            <span style="cursor :pointer; overflow: hidden;" class="flex flex-a-i-center">
              <span style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                {{$store.state.enterprise.enterpriseInfo ? $store.state.enterprise.enterpriseInfo.orgName : ''}}
              </span>
              <span style="font-size: 14px;margin-left: 10px;color: rgba(0, 0, 0, 0.8);">
                <CaretDownOutlined />
              </span>
            </span>
            <template #overlay>
              <a-menu :selectedKeys="[Number($route.params.orgId)]" @select="handleMenuClick" selectable>
                <a-menu-item
                  v-for="(item) in $store.state.enterprise.enterpriseList"
                  :key="item.orgId">
                  {{item.orgName}}
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
      </div>
      <div class="header-right-con">
        <a-tooltip placement="bottom" :title="$t('header.message')" arrow-point-at-center>
          <a-badge :count="messageNum" :overflow-count="99">
            <IconFont  type='icon-tongzhi' class="header-icon" @click="showMessage"/>
          </a-badge>
        </a-tooltip>
        <header-action :isBreakpoint="isBreakpoint"/>
      </div>
    </a-layout-header>
    <a-layout style="display: none;">
      <a-layout-sider
        :width="0"
        class="client-layout-sider"
        breakpoint="lg"
        collapsed-width="0"
        @breakpoint="onBreakpoint">
      </a-layout-sider>
    </a-layout>
    <a-layout class="client-layout-conten-wrap">
      <a-layout-content class="client-layout-content">
        <router-view />
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
// import { UserOutlined } from '@ant-design/icons-vue'
import { defineComponent, onMounted, onUnmounted, reactive, toRefs } from 'vue'
import { CaretDownOutlined } from '@ant-design/icons-vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { client } from '@/router/path'
import HeaderAction from '@/components/business/HeaderAction.vue'
import api from '@/services/api'
import { COMMON, CLIENT } from '@/store/type'
import AMap from 'AMap'
import config from '@/config'
export default defineComponent({
  components: {
    HeaderAction,
    CaretDownOutlined
  },

  setup () {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()

    let interval = null

    // const store = useStore()
    // console.log(router)
    let state = reactive({
      isBreakpoint: false,
      activeKey: [client.PROJECT_LIST],
      clientPath: client,
      messageNum: 0
    })

    const onLogoClick = () => {

    }

    const showMessage = () => {
      store.dispatch(COMMON.SET_IS_MESSAGE_MODAL_SHOW, !store.state.isMessageModalShow)
    }

    const setNumInterval = () => {
      sendNum()
      interval = setInterval(() => {
        sendNum()
      }, 5000)
    }

    const cancelNumInterval = () => {
      clearInterval(interval)
    }

    const sendNum = async () => {
      await getMessageNum()
    }

    const getMessageNum = async () => {
      try {
        const res = await api.moment.getMessageNum()
        if (res.code === 200) {
          if (res.data.noticeCategories) {
            res.data.noticeCategories.map(i => {
              res.data[i.typeId] = i.count
            })
            state.messageNum = res.data.unRead
          }
        } else {
          cancelNumInterval()
        }
      } catch {
        cancelNumInterval()
      }
    }

    const onBreakpoint = (val) => {
      state.isBreakpoint = val
      console.log(val)
    }

    const onTabSelect = ({ key }) => {
      switch (key) {
        case client.WORKBENCH:
          // 工作台
          break
        case client.PROJECT_LIST:
          // 项目列表
          break
        case client.PROJECT_COLLECTION:
          // 项目收藏
          break
        default:
          break
      }
      router.replace({
        name: key,
        params: router.currentRoute.value.params
      })
    }

    const handleMenuClick = ({ key }) => {
      store.dispatch('enterprise/' + CLIENT.ENTERPRISE.SET_SELECTED, key)
      router.replace({
        name: route.name,
        params: {
          ...route.params,
          orgId: key
        },
        query: route.query
      }).then(() => {
        window.location.reload()
      })
    }

    const checkArea = (isInland) => {
      const jumpUrl = config[isInland ? 'INLAND_URL' : 'FOREIGN_URL'] + window.location.pathname + `/?token=${store.state.token}`
      // window.open(jumpUrl, '_self')
      window.location.replace(jumpUrl)
    }

    const getCity = () => {
      const citySearch = new AMap.CitySearch()
      citySearch.getLocalCity((status, result) => {
        let isInland = false
        if (status === 'complete' && result.info === 'OK') {
          if (result && result.adcode && config.FOREIGN_LIST && !(config.FOREIGN_LIST.includes(result.adcode))) {
            isInland = true
          }
        } else {
          return
        }
        const local = window.location.origin
        if (!config.FOREIGN_URL || !config.INLAND_URL || !config.FOREIGN_LIST) return
        if ((!isInland && (local !== config.FOREIGN_URL)) || (isInland && (local === config.FOREIGN_URL))) {
          checkArea(isInland)
        }
      })
    }

    onMounted(() => {
      // if (store.state.locale) state.selectedLang = [store.state.locale]
      getCity()
      state.activeKey = [router.currentRoute.value.name]
      setNumInterval()
    })

    onUnmounted(() => {
      cancelNumInterval()
      state = null
    })

    return {
      ...toRefs(state),
      onBreakpoint,
      onLogoClick,
      onTabSelect,
      showMessage,
      handleMenuClick
    }
  }

})
</script>

<style lang="less" scoped>
</style>

<style lang="less">
.client-layout-con {
  width: 100%;
  height: 100%;
  background: #fff;
  position: relative;
  user-select: none;
  .client-layout-sider {
    // display: none;
  }
  .client-layout-header {
    padding: 0 20px;
    width: 100%;
    height: 64px;
    background: #fff;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 9;
    .page-title {
      font-size: 16px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.8);
    }
    .header-left-con {
        width: calc(100% - 240px);
        display: flex;
        align-items: center;
        .project-list-title{
          color: rgba(22, 23, 23, 0.8);
          font-size: 23px;
          font-weight: 550;
          overflow: hidden;
        }
        .ant-menu {
            width: calc(100% - 150px);
            min-width: 20px;
        }
        &-lg {
          width: calc(100% - 180px);
          .ant-menu {
            width: calc(100% - 50px);
          }
        }
    }
    .header-right-con {
      display: flex;
      align-items: center;

      .header-icon {
        margin-left: 24px;
        cursor: pointer;
      }
      .header-icon-lg {
        margin-left: 8px;
      }
    }
  }
  .client-layout-conten-wrap {
    // padding-top: 10px;
    width: 100%;
    height: calc(100% - 64px);
    position: relative;
    .client-layout-content {
    //   padding: 0 20px;
      background: #fff;
      width: 100%;
      height: 100%;
    }
  }
  .client-layout-padding-lg {
    padding: 0 10px !important;
  }
}
</style>
